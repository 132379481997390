@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";

@import "~prismjs/themes/prism-tomorrow.css";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/plugins/diff-highlight/prism-diff-highlight.css";
@import "~prismjs/plugins/line-highlight/prism-line-highlight.css";
@import "~prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "~prismjs/plugins/match-braces/prism-match-braces.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

:root, [data-bs-theme=dark] {
    --bg-navbar-dark: #1F2937;
    --bg-body: var(--bg-navbar-dark);
}

@include color-mode(dark) {}

@include color-mode(light) {}